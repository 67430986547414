<!-- HISTORY:
- V240627.1: Added filter2, columnsSequence, sortColumn, and sortOrder props + 
   Handled merging/blending data with multi-columns, Sequence and sorting of the columns + drill down (click event).
- V240617.1: Formatted Total column.
- V240614.1: Added chartData2 prop and merged it into chartData.
- V240401.1: Ignored total columns with error instead of showing in red.
- V240326.2: Fixed totalListCount calculation + Managed to display totals only if was a valid non-zero number +
   Managed to show error columns in red.
- V240322.1: Supported Total columns for the calculated columns as well by resolving the calculated column expressions.
- V230725.1: Added logic to support resolving listCount variable in the calculated columns.
- V230605.1: (On Aref side) Reverted back the previous changes from V230602.2.
- V230602.2: Fixed getActualDim() to handle event_data as well (by removing 'event_data.').
- V230602.1: Added __seq to tableData to prevent duplicate key error.
- V230131.2: Removed mappedtableHeaders and changed the headers in getHeader().
- V230131.1: Used json.parse instead of ... to make a copy of tableHeaders.
- V230130.1: Added chartsSettings prop and consumed it to map table headers + Replaced hasOwnProperty() with hasOwn() in mixins.
- 07/07/22(B0.8): Added predefinedFilter prop + Modified cellClicked() to handle click event for predefined filters.
- 06/14/22(B0.7): Added loading prop and replaced it with loadingTableData.
- 06/09/22(B0.6): Fixed the bug that sent wrong clickData upon sorting.
- 06/09/22(B0.5): Added ability to use the totals in the calculations by using $$ in front of the field name.
- 06/08/22(B0.4): Added filter prop to provide the actual field name for clickData + Added a total row to the end of table.
- 05/18/22(B0.3): Added toString() while adding calculatedColumns + Aligned headers to the end.
- 05/17/22(B0.2): Renamed the component from BtCalculatedTable to BtCalculatedTableForCs.
- 10/20/21(B0.1): Copied from BtHelpers project to be consumed in the BtChart component.
-->
<!--
   font-weight-black, font-weight-bold, font-italic
   text-left, text-center, text-right
   text-decoration-none, text-decoration-underline
   text--primary, text--secondary, text--disabled
-->
<template>
   <v-data-table dense
      class="elevation-1 mb-2"
      :hide-default-header="false"
      :footer-props="footerProps"
      :headers="tableHeaders"
      :hide-default-footer="tableData.length <= footerProps.itemsPerPageOptions[0]"
      item-key="__seq"
      :items="tableData"
      :items-per-page="itemsPerPage"
      :loading="loading"
      loading-text="Loading data. Please wait..."
      no-data-text="No data are available."
      no-results-text="No matching data were found."
   >
      <template v-slot:body="{ items }">
         <tbody>
            <tr v-for="(item, i) in items" :key="i">
               <td v-for="(header, j) in tableHeaders" :key="header.value"
                  class="px-0"
               >
                  <div
                     style="height:98%;"
                     :class="`px-0 ${calculatedColumns.find(cc => cc.name === header.value) ? backgroundClass : ''}`"
                  >
                     <div v-if="j === 0"
                        class="px-2 d-flex black--text"
                     >{{ item[header.value] }}</div>
                     <div v-else-if="!item[header.value] || isNaN(item[header.value]) || !Number.isFinite(item[header.value])"></div>
                     <!-- <div v-else-if="chartData2?.length && header.isBlended"
                        class="px-2 d-flex black--text justify-end"
                     >{{ formatValue(item[header.value], header.value) }}</div> -->
                     <div v-else-if="calculatedColumns.find(cc => cc.name === header.value)"
                        class="px-2 d-flex justify-end black--text"
                     >{{ formatValue(item[header.value], header.value) }}</div>
                     <a v-else
                        style="text-decoration: none;"
                        :class="`px-2 d-flex justify-end ${i != lastClickedRowInd || j != lastClickedColInd ? 'black--text' : 'blue--text font-weight-bold font-italic'}`"
                        href="#"
                        @click="cellClicked(item[tableHeaders[0].value], header.value, i, j, header.isBlended)"
                     >{{ formatValue(item[header.value], header.value) }}</a>
                  </div>
               </td>
            </tr>
            <tr class="blue-grey lighten-4">
               <td class="px-2">Total:</td>
               <td v-for="(header, i) in tableHeaders.slice(1)" :key="i" class="px-0 py-3">
                  <div v-if="Object.prototype.hasOwnProperty.call(header, 'TotalCol_' + header.value)">
                     <span v-if="Number(header['TotalCol_' + header.value])"
                        class="font-weight-bold px-2 d-flex justify-end"
                     >{{formatValue(header['TotalCol_' + header.value], header.value)}}</span>
                     <!-- <span v-else
                        class="red--text px-2 d-flex justify-end"
                     >{{header['TotalCol_' + header.value]}}</span> -->
                  </div>
               </td>
            </tr>
         </tbody>
      </template>
   </v-data-table>
</template>

<script>
import { hasOwn } from '../mixins/bt-mixin.js';

const NAME = "BtCalculatedTableForCs";
const MSG = `-----${NAME} V240627.1 says => `;

export default {
   name: NAME,

   props: {
      calculatedColumns: {
         //[{name:'',expression:'1 or more {{}}',fix:'none::|pre::text|post::text',position:'s::|e::|b::name|a::name'}
         type: Array,
         default: () => []
      },
      chartData: {
         type: Array,
         required: true,
         default: () => []
      },
      chartData2: {
         type: Array,
         required: false,
         default: () => []
      },
      chartsSettings: {
         type: Object
      },
      columnsSequence: {
         type: Array,
         default: () => []
      },
      debug: {
         type: Boolean,
         default: false
      },
      filter: {
         type: Object,
         required: true
      },
      filter2: {
         type: Object,
         required: false,
         default: () => {}
      },
      footerProps: {
         type: Object,
         default: () => { 
            return {
               itemsPerPageOptions: [5, 10, 20],
               showFirstLastPage: true
            }
         }
      },
      itemsPerPage: {
         type: Number,
         default: 5
      },
      loading: {
         type: Boolean,
         default: false
      },
      predefinedFilter: {
         type: Object
      },
      sortColumn: {
         type: String
      },
      sortOrder: {
         type: String
      }
      // options: {
      //    type: Object,
      //    default: () => {
      //       return {
      //          "class":"elevation-1 mt-2 mb-2 font-weight-light caption",
      //          // "class":"font-weight-bold body-2 font-italic"
      //          "align": "right"
      //       }
      //    }
      // }
   },

   data() {
      return {
         backgroundClass: 'grey lighten-3',
         rowDim: '',
         colDim: '',
         lastClickedRowInd: -1,
         lastClickedColInd: -1,
         actualRowDin: '',
         actualColDim: '',
         actualColDim2: '',
         mappedTableHeaders: []
      }
   },

   computed: { },

   watch: {
      chartData: {
         immediate: true,
         deep: true,
         handler() {
            // this.log('in chartData watch');
            this.init();
         }
      },

      chartData2: {
         immediate: true,
         deep: true,
         handler() {
            // this.log('in chartData2 watch');
            this.init();
         }
      },

      calculatedColumns: {
         immediate: false,
         deep: true,
         handler() {
            // this.log('in calculatedColumns watch');
            this.init();
         }
      }
   },

   methods: {
      _alert(msg) {
         if (this.debug) {
            alert(msg);
         }
      },
      log(msg, isError) {
         if (isError)
            console.error(`${MSG}${msg}`);
         else if (this.debug) {
            console.log(`${MSG}${msg}`);
            // alert(`${MSG}${msg}`);
         }
      },

      findVariables(expression) {
         const startMark = "{{";
         const endMark = "}}";
         const regEx = /{{[^{]+}}/g;
         const matches = expression.match(regEx) || [];
         // this.log('matches=' + JSON.stringify(matches));
         const vars = [];

         matches.forEach(match => {
            const key = match.replace(startMark, '').replace(endMark, '');
            // this.log('match=' + match + ', key=' + key);
            if (key)
               vars.push(key);
         });

         // this.log('vars=' + JSON.stringify(vars));
         return vars;
      },

      formatValue(val, header ='') {
         let result = new Intl.NumberFormat().format(val);
         const calCol = this.calculatedColumns.find(cc => cc.name === header);
         if (calCol) {
            const fixParts = calCol.fix.split('::');
            if (fixParts[1] === '%') {
               result = new Intl.NumberFormat('en-US',
                  {
                     minimumFractionDigits: 2,
                     maximumFractionDigits: 2
                  }
               ).format(val);
            }
            
            if (fixParts[0] === 'pre')
               result = fixParts[1] + result;
            else if (fixParts[0] === 'post')
               result += fixParts[1];
         }
         // this.log('in formatValue: result=' + result);
         return result;
      },

      init() {
         this.tableHeaders = [];
         this.tableData = [];
         if (!this.chartData.length || !this.calculatedColumns.length)
            return;

         const originalHeaders = [];
         const blendingHeaders = [];
         const blendingFields = new Set();
         let aggregateKey, idKey2;
         // this._alert('init() started: chartData=' + JSON.stringify(this.chartData));
         const dims = Object.keys(this.chartData[0]._id);
         // this._alert('chartData[0]=' + JSON.stringify(this.chartData[0]) +
         //    '\ndim=' + JSON.stringify(dims));
         this.rowDim = dims[0];
         this.colDim = dims[1];
         this.actualRowDim = this.getActualDim(this.rowDim);
         this.actualColDim = this.getActualDim(this.colDim);
         this.actualColDim2 = '';   //this.getActualDim2();
         // this._alert('chartData2=' + JSON.stringify(this.chartData2));
         if (this.chartData2?.length) {
            aggregateKey = Object.keys(this.chartData2[0])[1];            
            const $group = this.filter2.standard.find(f => f.$group).$group;
            const idKeys = Object.keys($group._id);
            if (idKeys.length === 2) {
               idKey2 = idKeys[1];
               this.actualColDim2 = $group._id[idKey2].replace('$events.', '').replace('$', '');
            } else
               blendingHeaders.push(this.getHeader(`${this.rowDim}_${aggregateKey}`, `${this.rowDim}_${aggregateKey}`, 'end', true));
         }
         // this._alert('rowDim=' + this.rowDim + ', colDim=' + this.colDim + '\nactualRowDim=' + this.actualRowDim + ', actualColDim=' + this.actualColDim + '\nactualColDim2=' + this.actualColDim2);

         for (let i = 0; i < this.chartData.length; i++) {
            const element = this.chartData[i];
            // alert('element='+JSON.stringify(element));
            const rowDimVal = element._id[this.rowDim];
            const colDimVal = element._id[this.colDim];
            // alert('rowDimVal=' + rowDimVal + ', colDimVal=' + colDimVal);
            if (!originalHeaders.find(h => h.value === colDimVal))
               originalHeaders.push(this.getHeader(colDimVal, colDimVal, 'end'));

            let item = this.tableData.find(d => d[this.rowDim] === rowDimVal);
            if (!item) {
               item = {};
               item[this.rowDim] = rowDimVal;
               this.tableData.push(item);
            }

            item[colDimVal] = element[Object.keys(element)[1]];

            if (aggregateKey) {
               const data2 = this.chartData2.filter(d => d._id[this.rowDim] === rowDimVal);
               if (data2.length) {
                  if (idKey2) {
                     data2.forEach(d => {
                        // console.log('d=' + JSON.stringify(d));
                        if (Object.keys(d._id).length === 2) {
                           blendingFields.add(d._id[idKey2]);
                           item[d._id[idKey2]] = d[aggregateKey];
                        }
                     });
                  } else
                     item[`${this.rowDim}_${aggregateKey}`] = data2[0][aggregateKey];
               }
            }
            // console.log('item=' + JSON.stringify(item));
         }

         blendingFields.forEach(h => {
            blendingHeaders.push(this.getHeader(h, h, 'end', true));
         });

         this.tableHeaders = [
            this.getHeader(this.rowDim, this.rowDim, 'start'),
            ...Array.from(blendingHeaders),
            ...originalHeaders
         ];
         // this._alert('tableHeaders=' + JSON.stringify(this.tableHeaders) + '\n\ntableData=' + JSON.stringify(this.tableData));


         const csLen = this.columnsSequence?.length || 0;
         if (csLen) {
            for (let index = 1; index < this.tableHeaders.length; index++) {
               const element = this.tableHeaders[index];
               // using '==' instead of '===' because value types are different.
               const seq = this.columnsSequence.findIndex(cs => cs == element.value);
               element.seq = seq > -1 ? seq + 1 : index + csLen;
            }
            this.tableHeaders.sort((a, b) => a.seq - b.seq);
         }


         if (this.sortColumn && Object.keys(this.tableData[0]).includes(this.sortColumn)) {
            this.log('in init(): tableData (BEFORE sorting): ' + JSON.stringify(this.tableData));
            if (this.sortOrder === 'asc')
               this.tableData.sort((a, b) => ((a[this.sortColumn] || 0) > (b[this.sortColumn] || 0)) ? 1 : -1);
            else
               this.tableData.sort((a, b) => ((a[this.sortColumn] || 0) < (b[this.sortColumn] || 0)) ? 1 : -1);
         } else
            this.log(`in init(): sortColumn (${this.sortColumn}) doesn't exist.`);


         this.tableData.forEach((row, i) => {
            Object.keys(row).forEach(key => {
               const totalKey = 'TotalCol_' + key;
               const val = row[key];
               if (!isNaN(val) && typeof val === 'number') {
                  const keyHeader = this.tableHeaders.find(h => h.value === key);
                  if (hasOwn(keyHeader, totalKey))
                     keyHeader[totalKey] += val;
                  else
                     keyHeader[totalKey] = val;
               }
            });
            row.__seq = i;
         });

         const groupObj = this.filter.standard.find(f => f.$group);
         const hasImport = groupObj && groupObj.$group._id.Import === '$importId';
         let sSeq = 0;
         let totalListCount = 0;
         let addCount;
         this.calculatedColumns.forEach(calCol => {
            addCount = totalListCount === 0;
            const header = this.getHeader(calCol.name, calCol.name, 'end', false, true);
            const positionParts = calCol.position.split('::');
            if (positionParts[0] === 's')
               this.tableHeaders.splice(1 + sSeq++, 0, header);
            else {
               const ind = this.tableHeaders.findIndex(h => h.value.toString().toLowerCase() === positionParts[1].toString().toLowerCase());
               if (ind === -1)
                  this.tableHeaders.push(header);
               else if (ind === 0)
                  this.tableHeaders.splice(1, 0, header);
               else {
                  if (positionParts[0] === 'b')
                     this.tableHeaders.splice(ind, 0, header);
                  else
                     this.tableHeaders.splice(ind + 1, 0, header);
               }
            }

            const calColHeaders = this.findVariables(calCol.expression);
            this.tableData.forEach(d => {
               let resolvedExpr = calCol.expression;
               // this._alert('resolvedExpr=' + resolvedExpr);
               calColHeaders.forEach(header => {
                  // this._alert('calColHeader=' + header);
                  if (hasOwn(d, header))
                     resolvedExpr = resolvedExpr.replace('{{' + header + '}}', d[header]);
                  else if (header === 'listCount' && hasImport) { //V230725.1
                     let count;
                     const importParts = d.Import ? d.Import.split(' ') : [];
                     if (importParts.length > 1) {
                        count = Number(importParts[importParts.length - 1].replace('(', '').replace(')', ''));
                        if (addCount)
                           totalListCount += count;
                     }
                     resolvedExpr = resolvedExpr.replace('{{' + header + '}}', count ? count : 0);
                     // alert('resolvedExpr=' + resolvedExpr);
                  } else {
                     const totalKey = header.replace('$$', 'TotalCol_');
                     const totalHeader = this.tableHeaders.find(h => hasOwn(h, totalKey));
                     if (totalHeader)
                        resolvedExpr = resolvedExpr.replace('{{' + header + '}}', totalHeader[totalKey]);
                     else {
                        this.log(`'${header}' not found!`);
                        resolvedExpr = resolvedExpr.replace('{{' + header + '}}', 0);
                     }
                  }
               });

               d[calCol.name] = eval(resolvedExpr);
               // this.log(`${calCol.name}=${d[calCol.name]}`);
            });
         });

         //for total row resolvation
         this.calculatedColumns.forEach(calCol => {
            const calColHeaders = this.findVariables(calCol.expression);
            // alert('calColHeaders=' + JSON.stringify(calColHeaders));
            let resolvedExpr = calCol.expression;
            let error = '';
            calColHeaders.forEach(header => {
               if (header === 'listCount' && hasImport)
                  resolvedExpr = resolvedExpr.replace('{{' + header + '}}', totalListCount);
               else {
                  let totalKey = 'TotalCol_' + header;
                  let totalHeader = this.tableHeaders.find(h => hasOwn(h, totalKey));
                  if (totalHeader)
                     resolvedExpr = resolvedExpr.replace('{{' + header + '}}', totalHeader[totalKey]);
                  else {
                     totalKey = header.replace('$$', 'TotalCol_');
                     totalHeader = this.tableHeaders.find(h => hasOwn(h, totalKey));
                     if (totalHeader)
                        resolvedExpr = resolvedExpr.replace('{{' + header + '}}', totalHeader[totalKey]);
                     else {
                        error = `'${header}' not found!`;
                        return;
                     }
                  }
               }
            });

            const tableHeader = this.tableHeaders.find(th => th.text === calCol.name);
            tableHeader['TotalCol_' + calCol.name] = error ? error : eval(resolvedExpr);
         });

         // this.mappedTableHeaders = [...this.tableHeaders];
         // this.mappedTableHeaders = JSON.parse(JSON.stringify(this.tableHeaders));
         
         // if (this.chartsSettings && this.chartsSettings.labels) {
         //    const labels = this.chartsSettings.labels;
         //    this.mappedTableHeaders.forEach(header => {
         //       const lbl = labels.find(l => l.id == header.text);
         //       if (lbl)
         //          header.text = lbl.label;
         //    });
         // }

         // this.loadingTableData = false;
         // console.log(`in ${NAME}: chartsSettings=${JSON.stringify(this.chartsSettings)}\ntableHeaders=${JSON.stringify(this.tableHeaders)}\nmappedTableHeaders=${JSON.stringify(this.mappedTableHeaders)}`);
         // this._alert(`in init(): tableData=${JSON.stringify(this.tableData)}`);
      },

      getActualDim(dim) {
         // this._alert('in getActualDim(): dim=' + dim + '\nfilter=' + JSON.stringify(this.filter));
         const $group = this.filter.standard.find(f => f.$group).$group;
         //V230602.2
         // return $group._id[dim].replace('$events.', '').replace('$', '');
         //V230605.1: Reverted back from V230602.2
         return $group._id[dim].replace('$events.', '').replace('$', '');  //.replace('event_data.', '')
      },

      // getActualDim2() {
      //    const $group = this.filter2?.standard?.find(f => f.$group)?.$group;
      //    if ($group) {
      //       const idKeys = Object.keys($group._id);
      //       if (idKeys.length === 2)
      //          return $group._id[idKeys[1]].replace('$events.', '').replace('$', '');
      //    }

      //    return '';
      // },

      getHeader(label, id, align, isBlended, addClass) {
         let lbl;
         if (this.chartsSettings && this.chartsSettings.labels)
            lbl = this.chartsSettings.labels.find(l => l.id == label);

         const header = { 
            text: lbl ? lbl.label : label,
            value: id,
            sortable: true,
            isBlended: isBlended || false
         };

         // if (this.options.hasOwnProperty('class'))
         //    header.class = this.options.class;
         // if (this.options.hasOwnProperty('align'))
         //    header.align = this.options.align;
         // else
            header.align = align;   //'start';
         if (addClass)
            header.class = this.backgroundClass;

         return header;
      },

      cellClicked(rowName, colId, rowInd, colInd, isBlended) {
         // this._alert(`in cellClicked(): rowName=${rowName}, colId=${colId}, rowInd=${rowInd}, colInd=${colInd}, isBlended=${isBlended}`);
         let clickData = null;
         if (rowInd === this.lastClickedRowInd && colInd === this.lastClickedColInd) {
            this.lastClickedRowInd = -1;
            this.lastClickedColInd = -1;
         } else {
            this.lastClickedRowInd = rowInd;
            this.lastClickedColInd = colInd;
            clickData = {};
            //B0.6: clickData[this.actualRowDim] = this.tableData[rowInd][this.rowDim];
            if (this.predefinedFilter && this.predefinedFilter.isRangeQuery) {
               const predefined = {};
               const myRowName = rowName.toString().toLowerCase();
               let rowNameParts = myRowName.split(' - ');
               if (rowNameParts.length === 2)
                  predefined[this.predefinedFilter.rowDimension] = {
                     $gte: Number(rowNameParts[0]),
                     $lt: Number(rowNameParts[1])
                  };
               else {
                  rowNameParts = myRowName.split(/under |< /);
                  if (rowNameParts.length === 2)
                     predefined[this.predefinedFilter.rowDimension] = {
                        $lt: Number(rowNameParts[1])
                     };
                  else {
                     rowNameParts = myRowName.split(/over |> /);
                     if (rowNameParts.length === 2)
                        predefined[this.predefinedFilter.rowDimension] = {
                           $gte: Number(rowNameParts[1])
                        };
                     else {
                        // alert (`In ${NAME}.cellClicked():Unexpected rowName!\nrowName=${rowName}`);
                        predefined[this.predefinedFilter.rowDimension] = rowName;
                     }
                  }
               }
               clickData['predefined'] = predefined;
            } else
               clickData[this.actualRowDim] = rowName;

            if (isBlended) {
               if (this.actualColDim2)
                  clickData[this.actualColDim2] = colId;
            } else
               clickData[this.actualColDim] = colId;
         }
         //Always logging for the troubleshooting purposes
         console.log('in BtCalculatedTableForCs.cellClicked(): clickData=' + JSON.stringify(clickData) + ', lastClickedRowInd=' + this.lastClickedRowInd + ', lastClickedColInd=' + this.lastClickedColInd);
         this.$emit('click', clickData);
      }
   },

   created() {
      // alert(`in created(): calculatedColumns=${JSON.stringify(this.calculatedColumns)}`);
      // this.log(`in created(): chartData=${JSON.stringify(this.chartData)}`);
   }
}
</script>
