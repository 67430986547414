<template>
<!-- TODO: 
   - add loading for aggregate
   - Set smaller fonts if more than 1 line
   -->
   <!-- HISTORY: no-version
   - V240821.1: Added String type to the kpi1 prop and considered it in formatKpiNumber() for the sake of BtContactProfileForCs.
   - V240809.2: Handled click event of the aggregated KPI +
      Only made filter with Count to be clickable + Added style to the clicked row.
   - V240808.2: Added aggregate template and kpiData and type and chartsSettings props +
      Renamed formatNumber() to formatKpiNumber and removed formatNumber2 in the favor of bt-mixin.
   - V230227.1: Copied the contents from Aref's version and fixed styles for the guage template.
   - 08/10/21: Copied the component from Helpers project to be used in BtChart component + Changed name from BtDatePicker to BtKpi +
      Displayed title and icon line only if title is provided.
   - 12/08/20: Added gaugeMaxValue prop and its related logic.
   - 09/22/20: Took gauge value out of the component and made it clickable..
   - 09/21/20: Added gauge option for ratio templates.
   - 09/18/20: Modified percentage calculation for ratio templates + Added displayRatioKpis prop to control visibility of kpi1 and kpi2 in ratio template.
   - 08/28/20: Removed hasKpi2 prop and used different template names instead.
   - 08/12/20: Added hasKpi2 prop to show/hide kpi2 and percentage.
   - 04/21/20: Changed title and kpi2 fonts.
   - 03/27/20: Placed % at the right side + Added formatNumbers prop.
   -->
   <div>
      <v-container fluid class="py-5 px-0">

         <v-toolbar v-if="title"
            flat color="white" height="40p">
            <h1 class="subtitle-1 font-weight-bold ml-2" style="color:#757575 !important">{{title}}</h1>
            <div class="flex-grow-1"></div>
            <v-icon class="mr-2">{{icon}}</v-icon>
         </v-toolbar>

         <v-divider v-if="title" class="my-0 py-0"></v-divider>

         <div v-if="templateName===tmplName.kpi1 || templateName===tmplName.internal">
            <v-toolbar flat color="white">
               <v-btn text
                  class="display-1 pl-0 ml-3"
                  :large="true"
                  @click="kpi1Clicked()"
               >{{formatKpiNumber(kpi1)}}
               </v-btn>
               <div class="flex-grow-1"></div>
               <div :class="percentageColor">
                  <span>{{percentage}} %</span>
                  <v-icon :class="percentageColor" style="margin-right:10px">{{percentageIcon}}</v-icon>
               </div>
            </v-toolbar>
            <v-row>
               <v-col cols="12" class="my-0 py-0 ml-4">
                  <span>{{kpi2Title}}:</span>
                  <v-btn text
                     class="caption pl-0"
                     :large="true"
                     @click="kpi2Clicked()"
                  >{{formatKpiNumber(kpi2)}}
                  </v-btn>
               </v-col>
            </v-row>
         </div>

         <div v-else-if="templateName===tmplName.master">
            <v-toolbar flat color="white">
               <v-btn text
                  class="display-1 pl-0 ml-3"
                  :large="true"
                  @click="kpi1Clicked()"
               >{{formatKpiNumber(kpi1)}}
               </v-btn>
            </v-toolbar>
         </div>

         <!-- <div v-else-if="templateName===tmplName.ratio">
            <v-toolbar flat color="white">
               <div class="display-1">
                  <span>{{percentage4Ratio}} %</span>
               </div>
            </v-toolbar>
         </div> -->
         <div v-else-if="templateName===tmplName.ratio" class="pt-2 pl-0 pr-2 pb-0">
            <v-layout v-if="useGauge"
               flat
               class="ml-3 pt-2 pb-1"
               color="white"
            >
               <!--
                  start-color="#9399ff"
                  end-color="lightgrey" 
                  :segments="2"
                  :custom-segment-stops="[0,percentageForRatio,actualGaugeMaxValue]"
                  :width="200"
                  :height="150"
                  needleColor="steelblue"
                     :current-value-text="`${formatNumber(kpi1)} / ${formatNumber(kpi2)}`"
               -->
               <v-row justify="center">
                  <vue-speedometer force-render
                     label-font-size="12px"
                     value-text-font-size="12px"
                     :minValue="0"
                     :maxValue="actualGaugeMaxValue"
                     :value="percentageForRatio"
                     current-value-text=""
                     :custom-segment-stops="[0,percentageForRatio,actualGaugeMaxValue]"
                     :segment-colors="[gaugeColors[0], gaugeColors[1]]"
                     :needle-height-ratio="gaugeNeedleHeightRatio"
                     :needle-color="`${gaugeColors[2]}`"
                     :ring-width="25"
                     :padding-horizontal="0"
                     :padding-vertical="0"
                     :width="180"
                     :height="150"
                     :fluid-width="false"
                  ></vue-speedometer>
               </v-row>
            </v-layout>
            <v-layout v-if="useGauge" flat color="white">
               <v-row justify="center" class="pt-0" v-if="useGauge">
                  <v-card flat class="caption py-1 pl-2 pr-1 ml-5" @click="kpi1Clicked()">{{formatKpiNumber(kpi1)}}</v-card>
                  <v-card flat>/</v-card>
                  <v-card flat class="caption py-1 pl-1" @click="kpi2Clicked()">{{formatKpiNumber(kpi2)}}</v-card>
                  <!-- <v-btn text depressed
                     class="caption px-0"
                     :small="true"
                     @click="kpi1Clicked()"
                  >{{formatNumber(kpi1)}}
                  </v-btn> /
                  <v-btn text depressed
                     class="caption px-0"
                     :small="true"
                     @click="kpi2Clicked()"
                  >{{formatNumber(kpi2)}}
                  </v-btn>  -->
               </v-row>
            <!-- <div class="flex-grow-1"></div> -->
            </v-layout>
            <v-layout v-else color="white" class="pt-5 pl-5 pb-3" flat>
               <div class="display-1 pr-5">
                  <span>{{percentageForRatio}} %</span>
               </div>
               <div class="flex-grow-1"></div>
               <div v-if="displayRatioKpis">
                  <v-btn text depressed
                     class="caption pl-0 pr-6"
                     :small="true"
                     @click="kpi1Clicked()"
                  >{{formatKpiNumber(kpi1)}}
                  </v-btn>
                  <br>
                  <v-btn text depressed
                     class="caption pl-0 pr-6"
                     :small="true"
                     @click="kpi2Clicked()"
                  >{{formatKpiNumber(kpi2)}}
                  </v-btn>
               </div>
            </v-layout>
         </div>

         <div v-else-if="templateName === tmplName.aggregate">
            <v-toolbar v-for="(data,i) in myData" :key="i"
               flat color="white"
            >
               <span v-if="operator === 'Count'">
                  <v-btn v-for="(value, key) in data" :key="key"
                     text
                     :class="`display-1 pl-0 ml-3${lastClickedValue === key ? ' blue--text font-italic font-weight-bold' : ''}`"
                     :large="false"
                     @click="kpiClicked(key)"
                  >{{type === types.s ? '' : key + ': '}}{{formatKpiNumber(value, 2)}}
                  </v-btn>
               </span>
               <span v-else>
                  <v-btn v-for="(value, key) in data" :key="key"
                     text
                     class="display-1 pl-0 ml-3"
                     style="cursor: default; pointer-events: none;"
                     :large="false"
                  >{{type === types.s ? '' : key + ': '}}{{formatKpiNumber(value, 2)}}
                  </v-btn>
               </span>
            </v-toolbar>
         </div>

         <div v-else>
            Invalid template name: {{templateName}}
         </div>

      </v-container>

   </div>
</template>

<script>
import VueSpeedometer from "vue-speedometer";
import { formatNumber } from '../mixins/bt-mixin.js';

export default {
   name: "BtKpi",

   components: { 
      VueSpeedometer
   },

   props: {
      templateName: {
         type: String,
         default: 'internal-date-range',
      },
      title: {
         type: String,
         default: ''
      },
      icon: {
         type: String,
         default: ''
      },
      kpi1: {
         type: [Number,String]
      },
      kpi2: {
         type: Number
      },
      kpi2Title: {
         type: String,
         default: ''
      },
      formatNumbers: {
         type: Boolean,
         default: true
      },
      // hasKpi2: {
      //    type: Boolean,
      //    default: true
      // },
      displayRatioKpis: {
         type: Boolean,
         default: true
      },
      useGauge: {
         type: Boolean,
         default: true
      },
      showGaugeNeedle: {
         type: Boolean,
         default: true
      },
      gaugeColors: {
         type: Array,
         default: () => ['#0f1f38','#8e7970','#f55449']
      },
      gaugeMaxValue: {
         type: [Number, String],
         default: "100"
      },
      /* V240808 - start */
      kpiData: {
         type: Array,
         default: () => []
      },
      type: {
         type: String
      },
      chartsSettings: {
         type: Object
      },
      filter: {
         type: Object
      },
      noDataText: {
         type: String,
         default: '0'
      }
      /* V240808 - end */
      // refProp: {
      //    type: Number,
      //    required: false
      // },
   },

   data() {
      return {
         tmplName: {
            kpi1: 'kpi-1',
            internal: 'internal-date-range',
            master: 'master-date-range',
            ratio: 'ratio-with-master-date-range',
            aggregate: 'with-groupby'
         },
         types: {
            s: 'scalar',
            t: 'table'
         },
         myData: [],
         lastClickedValue: null
      };
   },

   methods: {
      prepareKpiData() {
         // alert('in prepareKpiData():' + this.templateName + '\n' + this.tmplName.aggregate +
         //    '\n' + this.kpiData?.length + '\n' + this.chartsSettings?.labels);
         this.myData = [];
         if (this.templateName === this.tmplName.aggregate && this.kpiData?.length && this.chartsSettings?.labels) {
            for (let index = 0; index < this.kpiData.length; index++) {
               const d = this.kpiData[index];
               const aggregateKey = Object.keys(d).find(k => k != '_id');
               const idKey = Object.keys(d._id)[0];
               const idVal = d._id[idKey];
               // NOTE: Keep == as the types of id and idVal are different!
               const lbl = this.chartsSettings.labels.find(l => l.id == idVal);
               const item = {};
               item[lbl?.label || idVal] = d[aggregateKey];
               this.myData.push(item);
               if (this.type === this.types.s)
                  break;
            }
         }
         
         // alert('in prepareKpiData: myData=' + JSON.stringify(this.myData));
      },

      formatKpiNumber(number, maxFractionDigits) {
         // alert(`in formatKpiNumber(): number=${number}, maxFractionDigits=${maxFractionDigits}`);
         if (!this.formatNumbers) {
            return Number(number) ? formatNumber(number, maxFractionDigits) : this.noDataText;
         } else if (number < 1000)
            return number;
         else if (number < 1000000) {
            return new Intl.NumberFormat(
               'en-US', 
               { 
                  maximumFractionDigits: 1
               }).format(number / 1000) + "K";
         }
         else
            return new Intl.NumberFormat(
               'en-US', 
               { 
                  maximumFractionDigits: 1
               }).format(number / 1000000000) + "M";
      },

      // formatNumber2(number) {
      //    return new Intl.NumberFormat(
      //       'en-US', { }).format(number);
      // },

      kpi1Clicked: function() {
         this.$emit("kpi1-click", this.kpi1);
      },

      kpi2Clicked: function() {
         this.$emit("kpi2-click", this.kpi2);
      },

      kpiClicked(val) {
         // alert(`in kpiClicked(): val=${JSON.stringify(val)}`);
         let clickData;
         if (val === this.lastClickedValue) {
            clickData = null;
            this.lastClickedValue = null;
         } else {
            this.lastClickedValue = val;
            const label = this.chartsSettings?.labels.find(l => l.label === val);
            clickData = {};
            clickData[this.eventField] = (label?.id || val).toString();
         }

         // alert('in kpiClicked(): clickData=' + JSON.stringify(clickData));
         this.$emit("click", clickData);
      }
   },

   computed: {
      percentage() {
         var p;
         if (this.kpi1 === this.kpi2)
            p = 0;
         else
            p = Math.abs(this.kpi1 - this.kpi2) / this.kpi2 * 100;

         return new Intl.NumberFormat(
            'en-US', 
            { 
               maximumFractionDigits: 1
            }).format(p);
      },

      percentageForRatio() {
         var p;
         if (this.kpi1 === 0)
            p = 0;
         else if (this.kpi2 === 0 || this.kpi1 === this.kpi2)
            p = 100;
         else
            p = this.kpi1 / this.kpi2 * 100;

         return Number(new Intl.NumberFormat(
            'en-US', 
            { 
               maximumFractionDigits: 1
            }).format(p));
      },

      percentageColor() {
         let color = '';
         if (this.kpi1 > this.kpi2)
            color = 'green--text';
         else if (this.kpi1 < this.kpi2)
            color = 'red--text';
         // else
         //    return '';

         // return this.templateName === this.tmplName.ratio ? 'display-1 ' + color : color;
         return color;
      },

      percentageIcon() {
         if (this.kpi1 > this.kpi2)
            return 'arrow_upward';
         else if (this.kpi1 < this.kpi2)
            return 'arrow_downward';
         else
            return '';
      },

      numericGaugeMaxValue() {
         const parsed = Number.parseInt(this.gaugeMaxValue);
         if (isNaN(parsed)) return 100;
         else return parsed;
      },

      actualGaugeMaxValue() {
         // return Math.max(100, this.percentageForRatio);
         return Math.max(this.numericGaugeMaxValue, this.percentageForRatio);
      },

      gaugeNeedleHeightRatio() {
         return this.showGaugeNeedle ? 0.8 : 0;
      },

      eventField() {
         const id = this.filter.standard.find(f => f.$group)?.$group._id;
         if (id) {
            const idKey = Object.keys(id)[0];
            const idVal = id[idKey].replace('$events.', '');
            return idVal;
         } else
            return null;
      },

      operator() {
         const groupObj = this.filter.standard.find(f => f.$group)?.$group;
         if (groupObj)
            return Object.keys(groupObj).find(k => k != '_id');
         else
            return null;
      }

   },

   watch: {
      kpiData: {
         immediate: true,
         deep: true,
         handler() {
            // alert('in kpiData watch: val=' + JSON.stringify(val));
            this.prepareKpiData();
         }
      },

      chartsSettings: {
         immediate: true,
         deep: true,
         handler() {
            // alert('in chartsSettings watch: val=' + JSON.stringify(val));
            this.prepareKpiData();
         }
      }
   },

   created() {
      // alert('in created: this.tmplName='+JSON.stringify(this.tmplName));
      // alert('actualGaugeMaxValue='+this.actualGaugeMaxValue +
      //    '\npercentageForRatio=' + this.percentageForRatio +
      //    '\ncolors=' + this.gaugeColors[0] + '|' + this.gaugeColors[1] + '|' + this.gaugeColors[2] +
      //    '\ngaugeNeedleHeightRatio=' + this.gaugeNeedleHeightRatio
      // );
   },
   mounted() {
      // alert('in mounted: this.tmplName='+JSON.stringify(this.tmplName));
   }
}
</script>

<style lang="stylus">
   div.v-toolbar__content {
      padding-top: 0 !important; padding-bottom: 0 !important;
   }
   svg.speedometer { height: 105px !important; }
</style>